import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as _ from 'lodash';

import { CompanyService } from '../../../../services/company-service';
import { UtilService } from '../../../../services/util-service';
import { StripeService } from '../../../../services/stripe-service';
import { AuthService } from '../../../../services/auth-service';
import { DateService } from '../../../../services/date.service';
import { ICompany } from '../../../_models/company';
import { IStripePlan } from '../../../_models/stripe-plan';
import { AuthRoutePath } from '../../../_models/auth';

import { TermsComponent } from '../../../_shared/components/terms/terms.component';
import { PrivacyComponent } from '../../../_shared/components/privacy/privacy.component';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';

import { EmailValidator } from '../../../../validators/email-validator';
import { CompanyValidator } from '../../../../validators/company-validator';
import { StripePlansInputComponent } from '../../inputs/stripe-plans-input/stripe-plans-input.component';
import { REGISTER_CODE_FORMAT } from '../../../../app/_shared/formats';

@AutoUnsubscribe()
@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  // Template display logic
  canShowStripePlans: boolean;
  canShowExtraRegistrationFields: boolean;
  isLoading: boolean;
  isThirdPartyRedirect: boolean;
  // parentForm
  form: FormGroup;
  extraRegistrationFieldsForm: FormGroup;
  items: FormArray;

  // Local variables and observables
  company: any;

  selectedCompany: ICompany;
  selectedCompany$: Observable<ICompany>;
  selectedCompanySubscription: Subscription;

  stripePlans: IStripePlan[];
  stripePlans$: Observable<IStripePlan[]>;
  stripePlansSubscription: Subscription;

  selectedStripePlan: IStripePlan;
  selectedStripePlan$: Observable<IStripePlan>;
  selectedStripePlanSubscription: Subscription;

  customerStripeToken: any;
  customerStripeToken$: Observable<any>;
  customerStripeTokenSubscription: Subscription;
  customerStripeTokenReceived: boolean;
  companyAccessCodeChangedSub: Subscription;
  DOBError: string;

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get confirmPassword() {
    return this.form.get('confirmPassword');
  }

  get companyAccessCode() {
    return this.form.get('companyAccessCode');
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private companyValidator: CompanyValidator,
    private emailValidator: EmailValidator,
    private authService: AuthService,
    private companyService: CompanyService,
    private utilService: UtilService,
    private stripeService: StripeService,
    private dateService: DateService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.initialiseVariables();
    this.route.queryParams.subscribe((params) => {
      this.initialiseRegisterForm(params.code);
    });
    this.setCompanyObservable();
    this.setStripePlansObservable();
    this.setSelectedStripePlansObservable();
    this.setCustomerStripeTokenObservable();
  }

  ngOnDestroy() {
  }

  private initialiseVariables() {
    this.isLoading = false;
    this.isThirdPartyRedirect = false;
    this.customerStripeTokenReceived = false;
  }

  private initialiseRegisterForm(registerCode) {
    this.form = this.fb.group(
      {
        email: [
          '',
          [Validators.required, Validators.pattern(this.authService.emailPattern)],
          this.emailValidator.emailValidator(),
        ],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        companyAccessCode: [
          registerCode || '',
          [Validators.required, Validators.pattern(REGISTER_CODE_FORMAT)],
          this.companyValidator.companyValidator(),
        ],
        mobileBillingPartner: ['lifeiq'],
        isAcceptTermsAndConditions: [false, [Validators.requiredTrue]],
        // Update user register condition for age from 18OrAbove to 16OrAbove - 04/02/2025
        is18OrAbove: [false, [Validators.requiredTrue]],
      },
      {
        validators: [
          this.authService.passwordMatchesValidator,
          this.authService.passwordPatternValidator,
        ],
      },
    );

    this.companyAccessCodeChangedSub = this.companyAccessCode.valueChanges
      .subscribe(code => {
        if (!code || !code.trim().length) {
          this.companyService.clearSelectedCompany();
        }
      });
  }

  private initializeExtraRegistrationFieldsForm() {
    this.extraRegistrationFieldsForm = this.fb.group({
      isAcceptMarketing: [false, []],
    });
  }

  private setCompanyObservable() {
    this.selectedCompany$ = this.companyService.selectedCompany$;
    this.selectedCompanySubscription = this.selectedCompany$.subscribe(selectedCompany => {
      this.selectedCompany = selectedCompany;
      if (this.selectedCompany.extraRegistrationFields) {
        this.initializeExtraRegistrationFieldsForm();
        this.canShowExtraRegistrationFields = true;
      } else {
        this.canShowExtraRegistrationFields = false;
        this.extraRegistrationFieldsForm = null;
      }
    });
  }

  private setStripePlansObservable() {
    this.canShowStripePlans = false;
    this.stripePlans$ = this.stripeService.stripePlans$;
    this.stripePlansSubscription = this.stripePlans$.subscribe((companyStripePlans) => {
      this.stripePlans = companyStripePlans;
      if (!this.utilService.isEmptyArray(companyStripePlans)) {
        this.canShowStripePlans = true;
        this.modalService.open(StripePlansInputComponent);
      } else {
        this.canShowStripePlans = false;
      }
    });
  }

  private setSelectedStripePlansObservable() {
    this.selectedStripePlan$ = this.stripeService.selectedStripePlan$;
    this.selectedStripePlanSubscription = this.selectedStripePlan$.subscribe((plan) => {
      this.selectedStripePlan = plan;
    });
  }

  private setCustomerStripeTokenObservable() {
    this.customerStripeToken$ = this.stripeService.customerStripeToken$;
    this.customerStripeTokenSubscription = this.customerStripeToken$.subscribe((token) => {
      if (!this.utilService.isEmptyObject(token)) {
        this.customerStripeToken = token;
        this.customerStripeTokenReceived = true;
      }
    });
  }

  private disableFormAndEnableLoader() {
    this.form.disable();
    this.isLoading = true;
  }

  private enableFormAndDisableLoader() {
    this.form.enable();
    this.isLoading = false;
  }

  private handleRegisterResponse(res) {
    if (res.success === false) {
      this.handleRegisterErrorResponse(res);
    } else {
      this.companyService.clearSelectedCompany();
      this.form.reset();
      this.router.navigateByUrl(AuthRoutePath.registerSuccessfully);
      // this.showRegisterSuccess();
    }
  }

  private showRegisterSuccess() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Registration successful!';
    modal.componentInstance.message = 'Please check your email to verify your account and before you login.';
    modal.componentInstance.singleButton = true;
    modal.result.then(() => this.router.navigateByUrl(AuthRoutePath.login));
  }

  private handleRegisterErrorResponse(res) {
    if (res.isEmailAlreadyExistsError) {
      this.showEmailAlreadyExists();
    } else {
      this.showUnknownError();
    }
  }

  private showEmailAlreadyExists() {
    const title = 'User already exists';
    const message = 'Please register with a different email, or login with this email';
    this.showErrorMessage(title, message);
  }

  private showUnknownError() {
    const title = 'Error occurred';
    const message = 'Unable to register at this time. Please try again.';
    this.showErrorMessage(title, message);
  }

  private showErrorMessage(title: string, message: string) {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = title;
    modal.componentInstance.message = message;
    modal.componentInstance.singleButton = true;
  }

  isFormSubmitDisabled() {
    if (this.canShowExtraRegistrationFields) {
      return this.form.invalid || this.extraRegistrationFieldsForm.invalid || this.isLoading;
    }
    return this.form.invalid || this.isLoading;

  }

  setInputOutlineClass(ctrl: AbstractControl) {
    return this.authService.setInputOutlineClass(ctrl);
  }

  onSubmitRegisterForm() {
    if (this.form.invalid) {
      return;
    }
    if (this.selectedCompany.id == undefined) {
      return;
    }

    this.disableFormAndEnableLoader();
    const _body = this.form.value;
    if (this.canShowExtraRegistrationFields) {
      Object.assign(_body, this.extraRegistrationFieldsForm.value);
    }
    _body.companyId = this.selectedCompany.id;
    if (this.customerStripeTokenReceived) {
      _body.stripeToken = this.customerStripeToken;
      _body.mobileBillingPartner = 'stripe';
      _body.planId = this.selectedStripePlan.planId;
    }
    this.authService.register(_body).subscribe((res) => {
      this.enableFormAndDisableLoader();
      this.form.reset();
      this.handleRegisterResponse(res);
    });
  }

  onOpenTermsOfServiceRequested() {
    this.modalService.open(TermsComponent);
  }

  onOpenPrivacyPolicyRequested() {
    this.modalService.open(PrivacyComponent);
  }

  // googleOAuthSignIn() {
  //   if (this.form.get('companyAccessCode').invalid) {
  //     this.form.get('companyAccessCode').errors;
  //     this.showErrorMessage('Registration Code Error', "Enter valid company code and Sign in with Google");
  //     return;
  //    }
  //    if (this.selectedCompany.id == undefined) {
  //     this.form.get('companyAccessCode').errors;
  //     this.showErrorMessage('Registration Code Error', "Enter valid company code and Sign in with Google");
  //     return;
  //   }
  //   this.authService.getGoogleAuthorization(this.companyAccessCode.value);
  // }
}
